import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import type { ApexMatch } from "@/game-apex/models/match.mjs";
import type { SharedStatsBreakdownTypes } from "@/shared/stats-breakdown-types.mjs";
import SharedStatsBreakdown from "@/shared/StatsBreakdown.jsx";
import { filterErrState } from "@/util/eval-state.mjs";
import { sanitizeNumber, toFixedNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber, formatToPercent } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function MatchTileStatistics(props: {
  match: ApexMatch;
  profileId: string;
}) {
  const stats = useStatsBreakdown(props);
  // @ts-ignore JSX component with terrible inherited types
  return React.createElement(SharedStatsBreakdown, stats);
}

function useStatsBreakdown({
  match,
  profileId,
}: {
  match: ApexMatch;
  profileId: string;
}): SharedStatsBreakdownTypes {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const matches = useMemo(() => {
    return Object.values(state.apex.matches).filter(
      (i) =>
        filterErrState(i) && // Filter errorless matches
        (i.playerMatchStats ?? []).find((j) => j.platformId === profileId) && // Filter matches only the player is in
        i.gameMode === match.gameMode, // Filter the correct game mode
    ) as Array<ApexMatch>;
  }, [match.gameMode, profileId, state.apex.matches]);
  const recentAvgs = getRecentAvg(matches, profileId);
  const player = match.playerMatchStats.find((i) => i.platformId === profileId);
  // Lets build our stats table
  const result = useMemo<SharedStatsBreakdownTypes>(() => {
    return {
      rowData: [
        [
          {
            title: t("common:stats.kills", "Kills"),
            dataPoint: player?.kills,
            recentAvg: recentAvgs.kills,
            Image: null,
            dataPointMeta: null,
          },
          {
            title: t("common:stats.assists", "Assists"),
            dataPoint: player?.assists,
            recentAvg: toFixedNumber(recentAvgs.assists, 1) || 0,
          },
          {
            title: t("common:stats.revives", "Revives"),
            dataPoint: player?.revivesGiven,
            recentAvg: toFixedNumber(recentAvgs.revivesGiven, 1) || 0,
          },
          {
            title: t("common:stats.damage", "Damage"),
            dataPoint: player?.damageDone,
            recentAvg: recentAvgs.damageDone,
            Image: null,
            dataPointMeta: null,
          },
          {
            title: t("common:accuracy", "Accuracy"),
            dataPoint: player?.hits / player?.shots,
            recentAvg: recentAvgs.hits / recentAvgs.shots,
            Image: null,
            dataPointMeta: null,
            formatter(language, accuracy) {
              return accuracy > 0
                ? t("lol:percent", "{{number}}%", {
                    number: (accuracy * 100).toLocaleString(language, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })
                : "-";
            },
          },

          {
            title: t("common:stats.headshots", "Headshots"),
            dataPoint: player?.headshots / player?.hits,
            recentAvg: recentAvgs.headshots / recentAvgs.hits,
            formatter: formatToPercent,
            Image: null,
            dataPointMeta: null,
          },
          {
            title: t("eft:match.stats.survivalTime", "Survival Time"),
            formatter(language, milliseconds) {
              return milliseconds
                ? t("common:minutes", "{{minutes}} mins", {
                    minutes: formatDuration(milliseconds, "m:ss"),
                  })
                : "-";
            },
            dataPoint: player?.survivalTime * 1000,
            recentAvg: recentAvgs.survivalTime * 1000, // Todo: Ask back-end for duration hist
            Image: null,
            dataPointMeta: null,
          },
        ].map((i) => ({
          ...i,
          dataPoint: sanitizeNumber(i.dataPoint),
          recentAvg: sanitizeNumber(i.recentAvg),
          formatter: i.formatter ?? formatterDefault,
        })),
      ],
      sections: [
        {
          leftTitleText: t("common:you", "You"),
          centerTitleText: t("common:general", "General"),
          rightTitleText: t(
            "common:recentnAvg",
            "Recent {{numberOfGames}} Avg.",
            {
              numberOfGames: matches.length,
            },
          ),
          comparisonOptions: [],
          selectedComparison: "",
          onChangeComparison: () => {},
        },
      ],
    };
  }, [matches, player, recentAvgs, t]);
  return result;
}

function formatterDefault(language, value: number): number | string {
  return Number.isNaN(value) ? "-" : formatToFixedNumber(language, value, 1);
}

function getRecentAvg(
  matches: Array<ApexMatch>,
  profileId: string,
): RecentAvgs {
  // Accumlate all possible numeric stats
  const result = matches.reduce((acc, match) => {
    const player = match.playerMatchStats.find(
      (i) => i.platformId === profileId,
    );
    for (const key in player) {
      const value = player[key];
      if (typeof value !== "number") continue;
      acc[key] = (acc[key] ?? 0) + value;
    }
    return acc;
  }, {}) as RecentAvgs;
  // Calculate the averages
  for (const key in result)
    result[key] = sanitizeNumber(result[key] / matches.length);
  return result;
}

type RecentAvgs = OnlyNumberProperties<ApexMatch["playerMatchStats"][0]>;
